<template>
    <div class="close-incident-button-wrapper">
      <a-button
        @click.prevent="openModal"
        class="close-incident-button btn-rounded"
        size="large"
        block
        type="danger"
        >Close Incident</a-button
      >
  
      <a-modal
        title="Close Incident"
        v-model="modalVisible"
        @ok="closeIncident"
        ok-text="Close Incident"
        :ok-button-props="{ props: { disabled: !closureMessage.trim() } }"
      >
        <loading-screen :is-loading="isSaving"></loading-screen>
        <p>Closed incidents are accessed via the admin portal</p>
        <a-textarea
          rows="4"
          v-model="closureMessage"
          :placeholder="placeholder"
          allow-clear
        />
      </a-modal>
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import LoadingScreen from "../../../components/LoadingScreen.vue";
  import incidents from "../../../api/incidents";
  export default {
    props: ["incident", "myPresenceId", "tenantId"],
    components: { LoadingScreen },
    data() {
      return {
        modalVisible: false,
        closureMessage: "",
        isSaving: false
      };
    },
    methods: {
      ...mapActions('incidents', {
        disposeOfIncident: 'disposeOfIncident'
      }),
  
      openModal() {
        this.modalVisible = true;
      },
  
      closeIncident() {
        let vm = this;
        vm.isSaving = true;
  
        let closeIncidentRequest = {
            Id: vm.incident.id,
            ByPresenceId: vm.myPresenceId,
            ClosingNote: vm.closureMessage
        };
  
        incidents.archiveIncident(vm.tenantId, closeIncidentRequest).then(() => {
            vm.isSaving = false;
            vm.disposeOfIncident(vm.incident.id);
            vm.$router.push('/sims/incidents');
            vm.$message.success('Incident closed successfully');
        }).catch(e => {
            console.log(e);
            vm.isSaving = false;
            vm.$message.error('Error closing incident');
        });
      },
    },
    computed: {
      // ...mapGetters("incidentViewer", {
      //   incident: "incident",
      // }),
  
      placeholder() {
        return `Please add closure message for "${this.incident.displayName}"`;
      },
    },
  };
  </script>
  
  <style>
  </style>